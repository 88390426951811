
export const API_URL = "https://ghi-report-api.mystrand.org/api"
//export const API_URL = "http://localhost:8000/api"

export const APP_ENV = "client"
// export const APP_ENV = "reviewer"

export const X_API_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiTmV0bWVkcyBQcm9kIEFQSSBVc2VyIiwiZW1haWwiOiJuZXRtZWRzcHJvZGFwaXVzZXJAc3RyYW5kbHMuY29tIiwiaWF0IjoxNjk2NTE2NjM3fQ.yvI-13Wyh0HdZdAbb5xXSRFuyV6LLmQsNghUETweWrA';

export const GET_SINGLE_REPORT_URL = (APP_ENV === "client") ?
    '/zoho/getSingleReport' :
    '/zoho/getSingleDraftReport';


const isUserClient = () => {

    return (APP_ENV === "client") ? true : false;
}

export default {
    isUserClient
}

export const ENV = "production"
